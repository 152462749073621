import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";

import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@material-ui/core";


import { updateSelectedUserIds } from "store/actions/account";
import { useDispatch, useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const POSClientTable = (props) => {
  const { className, clientList, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const updateUserIds = (ids) => dispatch(updateSelectedUserIds(ids));
  const userFilter = useSelector((state) => state.account.filter);

  const handleSelectAll = (event) => {
    

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = clientList.map((user) => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
    updateUserIds(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const filterUsers = () => {
    if (!userFilter || userFilter === null || userFilter.trim() === "") {
      return clientList;
    }

    return clientList.filter(
      (user) =>
        (user.restaurantId && user.restaurantId.toLowerCase().includes(userFilter)) ||
        (user.deviceName && user.deviceName.toLowerCase().includes(userFilter)) ||
        (user.version &&  user.version.includes(userFilter)) ||
        (user.buildNumber && String(user.buildNumber).includes(userFilter)) ||
        (user.os && String(user.os).includes(userFilter))
    );
  };

  const getTimeString = (date) => {
    if (typeof date === "object" && '_seconds' in date) {
      return moment(new Date(date._seconds * 1000)).format('DD/MM/YYYY')
    }
    return moment(date * 1000).format('DD/MM/YYYY HH:mm')
  };

  const getTypesString = (types) => {
    const names = types.map(type => type.tableNumber);
    return names.join(', ');
  }

  return (
    <Grid lg={12} md={12} xl={12} xs={12}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          {/* <PerfectScrollbar> */}
          <div className={classes.inner}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      {/* <Checkbox
                        checked={selectedItems.length === clientList.length}
                        color="primary"
                        indeterminate={
                          selectedItems.length > 0 &&
                          selectedItems.length < clientList.length
                        }
                        onChange={handleSelectAll}
                      /> */}
                    </TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Printer Zone</TableCell>
                   
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterUsers()
                    .slice(page * rowsPerPage, rowsPerPage * page + rowsPerPage)
                    .map((client) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={client.id}
                        selected={
                          selectedItems.indexOf(client.id) !== -1
                        }
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedItems.indexOf(client.id) !== -1 
                            }
                            color="primary"
                            onChange={(event) =>
                              handleSelectOne(event, client.id)
                            }
                            value="true"
                          />
                        </TableCell>
                        <TableCell>

                   
                              <Typography
                                variant="body1"
                                className={classes.textLink}
                              >
                                {" "}
                                {client.posClientName}
                              </Typography>
                            
                         
                       
                        </TableCell>
                        <TableCell>{client.posClientType}</TableCell>
                        <TableCell>{client.username}</TableCell>
                      
                        <TableCell> {client.printerZones && client.printerZones.map(zone => zone.zoneName).join(';')}</TableCell>
                     
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* </PerfectScrollbar> */}
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filterUsers().length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

POSClientTable.propTypes = {
  className: PropTypes.string,
  clientList: PropTypes.array.isRequired,
};

export default POSClientTable;
