import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  // CardActions,
  Divider,
  Button,
  // Grid,
  Typography,
  Toolbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Box,
  RadioGroup, FormControlLabel, Radio, Collapse, FormControl, FormLabel
} from "@material-ui/core";

import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // If you're using date-fns
// import MomentUtils from '@date-io/moment'; // If you're using moment.js

import { useSelector, useDispatch } from "react-redux";
import { createAydenStore, getTerminals, transferPayout, setupPayoutSchedule, getSweeps, deleteSweep } from "apis/paymentAPIs";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import CircularProgress from '@material-ui/core/CircularProgress';

import { useQuery, useMutation, useQueryClient } from "react-query";

const useStyles = makeStyles((theme) => ({
  root: {},
  progess: {
    position: "absolute",
  },
  bottomButtons: {
    marginTop: theme.spacing(1),
  },
  title: {
    margin: theme.spacing(1),
  },
  item: {
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  cardContent: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  firstButton: {
    marginRight: theme.spacing(2),
  },
  tableCell: {
    padding: theme.spacing(2),
    borderBottom: 'none',
  },
  deleteButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const AdyenSettings = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [buttonLoading, setButtonLoading] = useState(false);
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const [transferMoneyDialogOpen, setTransferMoneyDialogOpen] =
    React.useState(false);
  const [payoutScheduleDialogOpen, setPayoutScheduleDialogOpen] =
    React.useState(false);
  const [transferAmount, setTransferAmount] = React.useState("");
  const [selectedTime, setSelectedTime] = React.useState(new Date());
  const [transferDescription, setTransferDescription] = React.useState('');
  const [transferTo, setTransferTo] = React.useState('balanceAccount');
  const [accountField, setAccountField] = React.useState('');
  const [BSBField, setBSBField] = React.useState('');

  const queryClient = useQueryClient();


  const transferMoney = () => {
    setTransferMoneyDialogOpen(true);
  };

  const setPayoutSchedule = () => {
    setPayoutScheduleDialogOpen(true);
  };

  const handleTransferMoneyDialogClose = () => {
    setTransferMoneyDialogOpen(false);
  };

  const handlePayoutScheduleDialogClose = () => {
    setPayoutScheduleDialogOpen(false);
  };

  const handleTransferMoneyConfirm = async () => {
    // Handle transfer money logic
    // check if transfer amount is valid and transfer description is valid
    if (transferAmount === "" || transferDescription === "") {
      showErrorMessage("Please fill in all the fields");
      return;
    }

    // check if transfer amount is valid
    if (isNaN(transferAmount)) {
      showErrorMessage("Please enter a valid amount");
      return;
    }
    if(transferTo === 'bankAccount' && (accountField === '' || BSBField === '')) {
      showErrorMessage("Please fill in all the fields");
      return;
    }

    try {
      setButtonLoading(true);
      await transferPayout(transferAmount, transferDescription, restaurant.id, transferTo, accountField, BSBField);
      showSuccessMessage("Successfully transfered money");
      // reset the values
      setTransferAmount("");
      setTransferDescription("");
      handleTransferMoneyDialogClose();
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to transfer money");
    } finally {
      setButtonLoading(false);
    }
    
    
  };

  const handlePayoutScheduleConfirm = async () => {

    if (!selectedTime) {
      showErrorMessage("Please fill in all the fields");
      return;
    }

   
    try {
      // get time from selected time
      const hours = selectedTime.getHours();
      const minutes = selectedTime.getMinutes();

      setButtonLoading(true);

      await setupPayoutSchedule(hours, minutes, restaurant.id);
      showSuccessMessage("Successfully set payout schedule");
      queryClient.invalidateQueries('sweepList');
      handlePayoutScheduleDialogClose();
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to set payout schedule");
    } finally {
      setButtonLoading(false);
    }
   
   
  };

  // write a function to parse hours and minutes from `"schedule":{"type":"cron","cronExpression":"30 21 * * *"}`
  const parseHoursAndMinutes = (schedule) => {
    if (!schedule) return '';
    const cronExpression = schedule.cronExpression;
    if (!cronExpression) return '';
    
    const hours = cronExpression.split(" ")[1];
    const minutes = cronExpression.split(" ")[0];
    return  `At ${hours}:${minutes}, every day (Australia/Melbourne)`
 
  };


  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

  // check is localhost
  const isLocalHost = window.location.hostname === "localhost";


  const { isLoading, isError, data, error } = useQuery(
    "terminalList",
    getTerminals
  );

  const { isLoading: isSweepsLoading, isError: isSweepsError, data: sweeps, error: sweepsError } = useQuery(
    ["sweepList", restaurant.id],
    () => getSweeps(restaurant.id)
  );

  const createStore = async () => {
    setButtonLoading(true);
    try {
      await createAydenStore();
      showSuccessMessage("Successfully created a store");
      
    
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to create a store");
    }

    setButtonLoading(false);
  };

 

  const { mutate: deleteSweepMutation, isLoading: isDeletingSweep } = useMutation(deleteSweep, {
    onSuccess: () => {
      queryClient.invalidateQueries("sweepList");
      dispatch(showSuccessSnackbar("Sweep deleted successfully."));
    },
    onError: (error) => {
      dispatch(showErrorSnackbar(error.message));
    },
  });

  function handleDelete(sweepId) {
    if (window.confirm("Are you sure you want to delete this sweep?")) {
      deleteSweepMutation({sweepId, restaurantId: restaurant.id});
    }
  }

  if (!restaurant || !restaurant.adyenPaymentSettings) {
    return (
      <Typography variant="h4" className={classes.title}>
        Please go to My Restaurant to set up Adyen account
        </Typography>
    );
  }


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <CardHeader subheader="Adyen settings" title="Adyen account" />
        <div>
       
          <Button
            color="primary"
            variant="outlined"
            onClick={transferMoney}
            disabled={buttonLoading}
            className={classes.firstButton}
          >
            Transfer Money
          </Button>
        
          <Button
            color="primary"
            variant="outlined"
            onClick={setPayoutSchedule}
            disabled={buttonLoading}
          >
            Set Payout Schedule
          </Button>
        </div>
      </Toolbar>
      <Divider />
      <CardContent className={classes.cardContent}>

      <TableContainer>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Field</TableCell>
        <TableCell>Value</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell>Account Holder Id</TableCell>
        <TableCell>{restaurant.adyenPaymentSettings.accountHolderId}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Balance Account Account</TableCell>
        <TableCell>{restaurant.adyenPaymentSettings.balanceAccount}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell>Legal Entity Id</TableCell>
        <TableCell>{restaurant.adyenPaymentSettings.legalEntityId}</TableCell>
      </TableRow>
      { restaurant.adyenPaymentSettings.sweepHour !== null  && restaurant.adyenPaymentSettings.sweepHour !== undefined ?
      <TableRow>
        <TableCell>Payout Schedule</TableCell>
        {sweeps && sweeps.length > 0 ? sweeps.map((sweep) => {
          return (
            <TableCell>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>{parseHoursAndMinutes(sweep.schedule)}</div>
              <div>  Target Amount: {sweep.targetAmount && sweep.targetAmount.value && !isNaN(sweep.targetAmount.value) ? `$${Number(sweep.targetAmount.value)/100}`: ''}</div>
              <Button onClick={() => handleDelete(sweep.id)} color="secondary" variant="contained" disabled={isDeletingSweep} className={classes.deleteButton}>
                Delete
                {isDeletingSweep && <CircularProgress size={30} className={classes.progess} />}
              </Button>
            </div>
          </TableCell>
          )
        }) : null
        }
        {/* <TableCell>At {restaurant.adyenPaymentSettings.sweepHour}:{restaurant.adyenPaymentSettings.sweepMinute}, every day (Australia/Melbourne)</TableCell> */}
      </TableRow>
      : null}
      <TableRow>
        <TableCell>Stores</TableCell>
        <TableCell>
          {restaurant.adyenPaymentSettings.stores
            ? restaurant.adyenPaymentSettings.stores
                .map((store) => store.reference)
                .join(', ')
            : 'Not set'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Terminal List</TableCell>
        <TableCell>
          {isLoading
            ? 'Loading...'
            : data &&
              data
                .map((terminal) => `${terminal.store}(${terminal.terminal})`)
                .join(', ')}
        </TableCell>
      </TableRow>
      {!restaurant.adyenPaymentSettings.stores ||
          restaurant.adyenPaymentSettings.stores.length < 2  || isLocalHost ? (
      <TableRow>
        <TableCell>Create a Store</TableCell>
        <TableCell>
       
            <Button
              color="primary"
              variant="outlined"
              onClick={createStore}
              disabled={buttonLoading}
            >
              Create a store
            </Button>
        
        </TableCell>
      </TableRow>  ) : null}
    </TableBody>
  </Table>
</TableContainer>

        {/* <Grid container spacing={6} wrap="wrap" row>
          <Grid className={classes.item} item md={4} sm={6} xs={12}>
            <Typography>
              Account Holder Id:{" "}
              {restaurant.adyenPaymentSettings.accountHolderId}
            </Typography>

            <Typography>
              Balance Account Account:{" "}
              {restaurant.adyenPaymentSettings.balanceAccount}
            </Typography>

            <Typography>
              Legal Entity Id: {restaurant.adyenPaymentSettings.legalEntityId}
            </Typography>

            <Typography>
              Stores:{" "}
              {restaurant.adyenPaymentSettings.stores
                ? restaurant.adyenPaymentSettings.stores
                    .map((store) => store.reference)
                    .join(", ")
                : "Not set"}
            </Typography>

            {!restaurant.adyenPaymentSettings.stores ||
            restaurant.adyenPaymentSettings.stores.length === 0 ? (
              <Button
                color="primary"
                variant="outlined"
                onClick={createStore}
                disabled={buttonLoading}
              >
                Create a store
              </Button>
            ) : null}
          </Grid>
          <Grid className={classes.item} item md={4} sm={6} xs={12}>
            <Typography>
              Terminal List:{" "}
              {isLoading
                ? "Loading..."
                : data &&
                  data
                    .map(
                      (terminal) => `${terminal.store}(${terminal.terminal})`
                    )
                    .join(", ")}
            </Typography>
          </Grid>
        </Grid> */}
      </CardContent>
      <Divider />
      {/* Transfer Money Dialog */}
      <Dialog
        open={transferMoneyDialogOpen}
        onClose={handleTransferMoneyDialogClose}
        aria-labelledby="transfer-money-dialog-title"
        aria-describedby="transfer-money-dialog-description"
      >
        <DialogTitle id="transfer-money-dialog-title">
          Transfer Money
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="transfer-money-dialog-description">
            Enter the amount you would like to transfer.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="transferAmount"
            label="Amount"
            type="number"
            value={transferAmount}
            onChange={(event) => setTransferAmount(event.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            id="transferDescription"
            label="Description"
            type="text"
            value={transferDescription}
            onChange={(event) => setTransferDescription(event.target.value)}
            fullWidth
          />

 {/* Transfer to radio group */}
 <FormControl component="fieldset" margin="dense">
      <FormLabel component="legend">Transfer to</FormLabel>
      <RadioGroup aria-label="transferTo" name="transferTo" value={transferTo} onChange={(event) => setTransferTo(event.target.value)}>
        {/* <FormControlLabel value="balanceAccount" control={<Radio />} label="Balance Account (From OrderBuddy to the merchant Adyen account)" /> */}
        <FormControlLabel value="merchantTerminalDeposit" control={<Radio />} label="Merchant Terminal Deposit" />
        <FormControlLabel value="merchantBalanceAccount" control={<Radio />} label="Merchant Balance Account (From the merchant Adyen account to OrderBuddy)" />
        <FormControlLabel value="transferInstrumentId" control={<Radio />} label="Transfer Instrument ID (From the merchant Adyen to the merchant bank)" />
        {/* <FormControlLabel value="bankAccount" control={<Radio />} label="Bank Account" /> */}
      </RadioGroup>
    </FormControl>

    {/* Collapse fields for bank account */}
    <Collapse in={transferTo === 'bankAccount'}>
      <TextField
        margin="dense"
        id="accountField"
        label="Account"
        type="text"
        onChange={(event) => setAccountField(event.target.value)}
        fullWidth
      />
      <TextField
        margin="dense"
        id="BSBField"
        label="BSB"
        type="text"
        onChange={(event) => setBSBField(event.target.value)}
        fullWidth
      />
    </Collapse>




        </DialogContent>



        <DialogActions>
          <Button onClick={handleTransferMoneyDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleTransferMoneyConfirm} disabled={buttonLoading} color="primary">
            Transfer
            {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Set Payout Schedule Dialog */}
      <Dialog
        open={payoutScheduleDialogOpen}
        onClose={handlePayoutScheduleDialogClose}
        aria-labelledby="payout-schedule-dialog-title"
        aria-describedby="payout-schedule-dialog-description"
      >
        <DialogTitle id="payout-schedule-dialog-title">
          Set Payout Schedule
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="payout-schedule-dialog-description">
          Choose the payout time every day
          </DialogContentText>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              value={selectedTime}
              onChange={(time) => setSelectedTime(time)}
              ampm={false}
              openTo="hours"
              views={["hours", "minutes", "seconds"]}
              format="HH:mm:ss"
              label="Payout Schedule Time"
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePayoutScheduleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePayoutScheduleConfirm} disabled={buttonLoading} color="primary">
            Set Schedule
            {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

AdyenSettings.propTypes = {
  className: PropTypes.string,
};

export default AdyenSettings;
