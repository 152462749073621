import React from "react";
import { makeStyles } from "@material-ui/styles";

import { TerminalListToolbar, TerminalTable } from "./components";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert } from "@material-ui/lab";

import {
  getAllTerminals,
  assignTerminals,
  updateTerminalToIntegration,
  updateTerminalToStandalone,
} from "apis/devicesAPIs";
import { useQuery, useMutation, useQueryClient } from "react-query";
import SearchProvider from "./SearchProvider";

import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";

import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
}));

const TerminalList = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));
  const showErrorMessage = (msg) => dispatch(showErrorSnackbar(msg));

  const queryClient = useQueryClient();

  const { isLoading, isError, data, error } = useQuery(
    "allTerminalList",
    getAllTerminals
  );

  const mutation = useMutation(assignTerminals, {
    onSuccess: () => {
      queryClient.invalidateQueries("allTerminalList");
      showSuccessMessage(
        "The terminal has been updated successfully and waiting for the terminal to be connected"
      );
    },
  });


  const updateTerminalToIntegrationMutation = useMutation(
    updateTerminalToIntegration,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allTerminalList");
        showSuccessMessage("The terminal has been updated successfully");
      },
    }
  );

  const updateTerminalToStandaloneMutation = useMutation(
    updateTerminalToStandalone,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allTerminalList");
        showSuccessMessage("The terminal has been updated successfully");
      },
    }
  );

  const assignTerminal = (selectedTerminal, isReturn = false) => {
    // console.log("selectedTerminal", selectedTerminal);
    if (!selectedTerminal) {
      showErrorMessage("Please select a terminal");
      return;
    }

    mutation.mutate({ terminal: selectedTerminal, isReturn });
  };

  const patchTerminalToIntegration = (selectedTerminal) => {
    if (!selectedTerminal) {
      showErrorMessage("Please select a terminal");
      return;
    }

    updateTerminalToIntegrationMutation.mutate({ terminal: selectedTerminal });
  }

  const patchTerminalToStandalone = (selectedTerminal) => {
    if (!selectedTerminal) {
      showErrorMessage("Please select a terminal");
      return;
    }
    updateTerminalToStandaloneMutation.mutate({ terminal: selectedTerminal });
  }

 

  if (isError) {
    return (
      <main className={classes.content}>
        <Alert severity="error">{`${error.message} `}</Alert>{" "}
      </main>
    );
  }

  if (isLoading === true) {
    return (
      <main className={classes.content}>
        {isLoading && (
          <CircularProgress size={150} className={classes.uiProgess} />
        )}
      </main>
    );
  } else {
    return (
      <div className={classes.root}>
        <SearchProvider>
          <TerminalListToolbar />
          <div className={classes.content}>
            {data && data.stores ? (
              <TerminalTable
                stores={data.stores}
                standaloneTerminalIds={data.standaloneTerminalIds}
                assignTerminal={assignTerminal}
                updateTerminalToIntegration={patchTerminalToIntegration}
                updateTerminalToStandalone={patchTerminalToStandalone}
              />
            ) : null}
          </div>
        </SearchProvider>
      </div>
    );
  }
};

export default TerminalList;
