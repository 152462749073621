import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import LinearProgress from "@material-ui/core/LinearProgress";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import {
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Button,
  Grid,
  TextField,
  RadioGroup,
  Radio,
  InputLabel,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getPrinterZones } from "apis/printAPIs";


import clsx from "clsx";
import { useQuery, useMutation } from "react-query";

import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "store/actions/snackbarMessage";
import { queryRestaurantUsers } from "apis/userAPIs";

import { createPOSClient } from "apis/devicesAPIs";
import userEvent from "@testing-library/user-event";



const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  root: {},
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "50%",
    top: "35%",
  },
  progess: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
}));



const POSClient = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();


  const [clientType, setClientType] = useState("");
  const [clientName, setClientName] = useState("");
  const [printerZones, setPrinterZones] = useState([]);
  const [usernameInput, setUsernameInput] = useState("");
  const [userEmail, setUserEmail] = useState("");


  const updateUserNameAndEmail = (user) => {
    
    if (user) {
      setUsernameInput(user.username);
      setUserEmail(user.email);
    }
  };


  const [buttonLoading, setButtonLoading] = useState(false);

  const history = useHistory();

  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccessMessage = (msg) => dispatch(showSuccessSnackbar(msg));

  
  // create createPOSClient mutation with clientName, clientType, username, printerZones use react-query
   const { mutate: createPOSClientMutation, isLoading: isCreateLoading } = useMutation(
    createPOSClient,
    {
      onSuccess: (data) => {
        console.log("createPOSClientMutation success", data);
        showSuccessMessage("POS Client created successfully");
        history.push("/posClients");
      },
      onError: (error) => {
        console.log("createPOSClientMutation error", error);
        showError("Something went wrong");
        setButtonLoading(false);
      },
    }
  );


  const { data: allPrinterZones, isLoading: isZoneLoading } = useQuery(
    "printerZones",
    getPrinterZones
  );

  const { data: allUsers, isLoading: isUserLoading } = useQuery(
    "allUsers",
    queryRestaurantUsers
  );

  const savePOSClient = (event) => {
    event.preventDefault();

    const payload = {
      posClientName: clientName,
      posClientType: clientType,
      username: usernameInput,
      userEmail,
      printerZones: printerZones,
    };

    console.log("payload", payload);
    setButtonLoading(true);
    createPOSClientMutation(payload);
  };

  const handlePrinterZoneChange = (event) => {
    // console.log(event.target.value);
    const selectedPrinterZones = [];
    event.target.value.forEach((pz) => {
      const zone = allPrinterZones.find((p) => p.id === pz);
      if (zone) {
        selectedPrinterZones.push(zone);
      }
    });

    // console.log("selectedPrinterZones", selectedPrinterZones);
    setPrinterZones(selectedPrinterZones);
  };

  const handleClientTypeChange = (event) => {
    setClientType(event.target.value);
  };

  return (
    <main className={classes.content}>
      {buttonLoading || isUserLoading || isZoneLoading ? <LinearProgress /> : null}
      <Card className={clsx(classes.root, classes)}>
        <CardHeader title="POS Client" subheader="Create POS Client" />
        <form autoComplete="off" noValidate>
          <CardContent>
            <Grid container spacing={3} direction="column">
            <Grid item xs={12}>
              <TextField
                id="client-name"
                label="Client Name"
                variant="outlined"
                fullWidth
                required
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Type</FormLabel>
                <RadioGroup aria-label="client-type" name="client-type" row
                  value={clientType}
                  onChange={handleClientTypeChange}>
                  <FormControlLabel
                    value="counter"
                    control={<Radio />}
                    label="Counter"
                  />
                  <FormControlLabel
                    value="client"
                    control={<Radio />}
                    label="Client"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
            <InputLabel id="printer-zone-label" style = {{marginBottom: 4}}>Login Username</InputLabel>
            <Select
                  value={usernameInput}
                  onChange={(e) => updateUserNameAndEmail(e.target.value)}
                >
                  {allUsers && allUsers.map((user) => (
                    <MenuItem key={user.username} value={user}>
                      {user.username} - {user.email}
                    </MenuItem>
                  ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
            <InputLabel id="printer-zone-label" style = {{marginBottom: 4}}>Printer Zone</InputLabel>
              <FormControl variant="outlined" fullWidth>
              
                <Select
                  multiple
                  value={
                    printerZones ? printerZones.map((zone) => zone.id) : []
                  }
                  onChange={handlePrinterZoneChange}
                >
                  {allPrinterZones && allPrinterZones.map((zone) => (
                    <MenuItem key={zone} value={zone.id}>
                      {zone.zoneName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions />
        </form>
      </Card>

      <Button
        color="primary"
        variant="contained"
        type="submit"
        className={classes.submitButton}
        onClick={savePOSClient}
        disabled={
          buttonLoading || !clientName || !usernameInput || !clientType
        }
      >
        Save Client
        {buttonLoading && (
          <CircularProgress size={30} className={classes.progess} />
        )}
      </Button>
    </main>
  );
};

export default POSClient;
