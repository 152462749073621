import API from "util/API";

export const createAydenStore = async () => {
  return await API.post("/createStore");
};

export const getTerminals = async () => {
  const response = await API.get("/terminals");
  if (response && response.data && response.data.terminals) {
    return response.data.terminals;
  }
  return [];
};


export const getSweeps = async (restaurantId) => {
  const response = await API.post("/getSweeps", {restaurantId});
  if (response && response.data && response.data.sweeps) {
    return response.data.sweeps;
  }
  return [];
};

export const deleteSweep = async ({restaurantId, sweepId}) => {
  return await API.post("/deleteSweep", {restaurantId, sweepId});
};

export const transferPayout = async (transferAmount, transferDescription, restaurantId, transferTo, accountField, BSBField) => {
  return await API.post("/transferPayotus", {transferAmount, transferDescription, restaurantId, transferTo, accountField, BSBField});
};


export const setupPayoutSchedule = async (hour, minute, restaurantId) => {
  return await API.post("/setupPayoutSchedule", {hour, minute, restaurantId});
};

export const createPaymentType = async (paymentType) => {
  return await API.post("/createPaymentType", paymentType);
};

export const updatePaymentTypes = async (paymentTypes) => {
  return await API.post("/updatePaymentTypes", {paymentTypes});
};

export const getAllPaymentTypes = async () => {
  const response = await API.get("/paymentTypes");
  if (response && response.data && response.data.paymentTypes) {
    return response.data.paymentTypes.sort((a, b) => Number(a.sort) - Number(b.sort));
  }
  return [];
}

export const getGrantOffers = async () => {
  const response = await API.get("/grantOffers");
  if (response && response.data && response.data) {
    return response.data;
  }
  return [];
}

export const requestGrantOffer = async (grantOfferId) => {
  return await API.post("/grants", {grantOfferId});
}

