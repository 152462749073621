import API from 'util/API';


export const getAllDevices = async () => {
  const response = await API.get('/allDevices');
  if (response && response.data && response.data.devices) {
    const devices = response.data.devices;
    // sort devices by updatedAt
    devices.sort((a, b) => {
      return b.updatedAt - a.updatedAt;
    });
    return devices;
  }
  return [];
};

export const getAllKiosks = async () => {
  const response = await API.get('/allKiosks');
  if (response && response.data && response.data.kiosks) {
    const kiosks = response.data.kiosks;
 
    return kiosks;
  }
  return [];
};



export const getAllTerminals = async () => {
  const response = await API.get('/allTerminals');
  if (response && response.data && response.data) {
    return response.data;
  }
  return null;
};

export const getMerchantTerminals = async () => {
  const response = await API.get('/terminals');
  if (response && response.data && response.data.terminals) {
    return response.data.terminals;
  }
  return [];
};

export const assignTerminals = async ({terminal, isReturn}) => {
 return await API.post('/assignTerminals', { terminal, isReturn });
};

export const updateTerminalToStandalone= async ({terminal}) => {
  return await API.put('/updateTerminalSettings', { terminalId: terminal , isStandaloneTerminal: true });
 };

 export const updateTerminalToIntegration= async ({terminal}) => {
  return await API.put('/updateTerminalSettings', { terminalId: terminal , isStandaloneTerminal: false });
 };


export const getNewKioskCode = async () => {
  const response = await API.get('/kioskCode');
  if (response && response.data && response.data) {
    return response.data;
  }
  return null;
};


export const getKiosk= async (kioskId) => {
  const response = await API.get(`/kiosk/${kioskId}`);
  if (response && response.data && response.data && response.data.kiosk) {
    return response.data.kiosk;
  }
  return null;
};


export const createKiosk= async (kiosk) => {
  const response = await API.post(`/kiosk`, { kiosk});
  if (response && response.data && response.data) {
    return response.data;
  }
  return null;
};


export const updateKiosk= async (kiosk) => {
  const response = await API.put(`/kiosk/${kiosk.id}`, { kiosk });
  if (response && response.data && response.data) {
    return response.data;
  }
  return null;
};

export const getAllPosClients = async () => {
  const response = await API.get('/posClients');
  if (response && response.data && response.data.clients) {
    const clients = response.data.clients;
 
    return clients;
  }
  return [];
};

export const createPOSClient = async (client) => {
  const response = await API.post('/posClient',  client );
  if (response && response.data && response.data) {
    return response.data;
  }
  return null;
};

export const deletePOSClient = async (clientId) => {
  const response = await API.delete(`/posClient/${clientId}`);
  if (response && response.data && response.data) {
    return response.data;
  }
  return null;
};